<script setup>

</script>

<template>
동물병원 HOME
  {
  "jurisdiction": "서울특별시",
  "shelterName": "서울시 동물보호센터",
  "contactPhone": "02-1234-5678",
  "location": {
  "latitude": 37.5665,
  "longitude": 126.9780,
  "addressName": "서울특별시 강남구 테헤란로 152",
  "addressDetail1": "메리츠팰리스 5층",
  "addressDetail2": "501호"
  },
  "websiteUrl": "https://www.animal.or.kr",
  "contactEmail": "animal@example.com"
  }
  수정버튼
  삭제버튼
  진입조회
</template>

<style scoped>

</style>