<script setup>

</script>

<template>
  보호센터 정보입력
</template>

<style scoped>

</style>